<template>
	<main>
		<HeaderTab :title="getTrad('monte.contract_list')"  :return_action="'navigationBack'"/>
		<div id="content">
			<shutter-panel ref="shutterPanel" />
		</div>
	</main>
</template>

<script type="text/javascript">
import Shutter from "@/mixins/Shutter.js"
import ShutterContract from "@/mixins/shutters-manager/Contract.js"

export default {
	name: 'Contract',
	mixins: [Shutter, ShutterContract],
	data () {
		return {}
	},

	mounted() {
		this.init_component()
	},

	methods: {
		init_component() {
			this.setupContractList()
		}
	},

	components: {
		HeaderTab: () => import('@/components/HeaderTab')
	}
};

</script>